import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import Card from './Card';
import '../App.css';
import { useLocation, useNavigate } from 'react-router-dom';
import UserContext from '../UserContext';
import './Nav.css';
import ProgressBar from './ProgressBar';
import { ThemeContext } from '../ThemeContext';


const HomePage = () => {
  const [agents, setAgents] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const location = useLocation();
  const urlNavigate = useNavigate();
  const [showMyAgents, setShowMyAgents] = useState(false);
  const { user, setUser } = useContext(UserContext);
  const [showLoadingMessage, setShowLoadingMessage] = useState(false);

  const filters = [
    "AI", "Data", "Integration", "Communication", "Database", "Finance", 
    "Search", "Automation", "Research", "Analytics", "langchain", "llama-index", 
    "openai", "crewai"
  ];
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [fetchError, setFetchError] = useState(false);
  const { theme, toggleTheme } = useContext(ThemeContext);


  const filterColors = {
    AI: { normal: '#a8e6cf', hover: '#91d4b7' },
    Data: { normal: '#dcedc1', hover: '#c6d7a8' },
    Integration: { normal: '#ffd3b6', hover: '#ffbfa0' },
    Communication: { normal: '#ffaaa5', hover: '#ff918f' },
    Database: { normal: '#ff8b94', hover: '#ff6d78' },
    Finance: { normal: '#a2d5f2', hover: '#8bc3de' },
    Search: { normal: '#c5cae9', hover: '#9fa8da' },
    Automation: { normal: '#ffccbc', hover: '#ffab91' },
    Research: { normal: '#ffe0b2', hover: '#ffcc80' },
    Analytics: { normal: '#f8bbd0', hover: '#e1a2b8' },
    langchain: { normal: '#b3e5fc', hover: '#81d4fa' },
    "llama-index": { normal: '#b2dfdb', hover: '#80cbc4' },
    openai: { normal: '#ffcc80', hover: '#ffb74d' },
    crewai: { normal: '#d1c4e9', hover: '#b39ddb' },
  };

  useEffect(() => {
    const fetchAgents = async () => {
      setFetchError(false);
      try {
        const baseUrl = 'https://agent-marketplace-2jmb.vercel.app';
        let url = showMyAgents ? `${baseUrl}/api/my-agents` : `${baseUrl}/api/agents`;
        if (showMyAgents && user?.email) {
          url += `?email=${encodeURIComponent(user.email)}`;
        }
        const response = await axios.get(url);
        setAgents(response.data);
        if (response.data.length === 0 && showMyAgents) {
          setFetchError(true);
        }
      } catch (error) {
        console.error('There was an error fetching the agents:', error);
        setFetchError(true);
  
        // Show loading message
        setShowLoadingMessage(true);
  
        // Reload the page after a short delay (e.g., 2 seconds)
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    };
    fetchAgents();
  }, [showMyAgents, user?.token, user?.email]);
  
  

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const searchQuery = searchParams.get('search');
    if (searchQuery !== null) {
      setSearchTerm(searchQuery);
    }
  }, [location.search]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (searchTerm) {
      searchParams.set('search', searchTerm);
    } else {
      searchParams.delete('search');
    }
    urlNavigate(`/?${searchParams.toString()}`, { replace: true });
  }, [searchTerm, urlNavigate, location.search]);

  const filteredAgents = agents.filter(agent =>
    (agent.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
     agent.description.toLowerCase().includes(searchTerm.toLowerCase())) &&
    (selectedFilters.length === 0 || selectedFilters.some(filter => filter === agent.tag || filter === agent.origin))
  );

  const toggleFilter = (filter) => {
    setSelectedFilters(prevFilters => {
      if (prevFilters.includes(filter)) {
        return prevFilters.filter(f => f !== filter);
      } else {
        return [...prevFilters, filter];
      }
    });
  };

  const toggleShowMyAgents = () => {
    setShowMyAgents(!showMyAgents);
  };

  const filterButtonStyles = (filter) => {
    const color = filterColors[filter] || { normal: 'transparent', hover: '#e0e0e0' };
    return {
      padding: '10px 20px',
      border: `1px solid ${selectedFilters.includes(filter) ? '#007bff' : color.normal}`,
      borderRadius: '20px',
      backgroundColor: selectedFilters.includes(filter) ? '#007bff' : color.normal,
      color: selectedFilters.includes(filter) ? 'white' : '#007bff',
      cursor: 'pointer',
      flex: '0 1 auto',
      maxWidth: '200px',
      '@media (max-width: 768px)': {
        padding: '8px 10px',
        fontSize: '14px',
      },
      transition: 'background-color 0.3s, border-color 0.3s, color 0.3s',
      ':hover': {
        backgroundColor: color.hover,
        borderColor: color.hover,
        color: 'white',
      }
    };
  };

  const clearFilterButtonStyles = {
    padding: '10px 20px',
    border: '1px solid #ccc',
    borderRadius: '20px',
    backgroundColor: 'transparent',
    color: theme === 'dark' ? 'white' : '#333',
    cursor: 'pointer',
    flex: '0 1 auto',
    maxWidth: '200px',
    '@media (max-width: 768px)': {
      padding: '8px 10px',
      fontSize: '14px',
    },
    transition: 'background-color 0.3s, border-color 0.3s, color 0.3s',
    ':hover': {
      backgroundColor: '#e0e0e0',
      borderColor: '#ccc',
      color: '#333',
    }
  };

  const filterContainerStyles = {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '10px',
    marginBottom: '20px',
    padding: '10px 20px',
    '@media (max-width: 768px)': {
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    }
  };

  const taglineStyles = {
    textAlign: 'center',
    fontSize: '1rem',
    color: theme === 'dark' ? '#c9c8c5' : '#333',
    marginBottom: '40px',
    maxWidth: '800px',
    lineHeight: '1.5',
    margin: '20px auto',
  };

  const navigate = (path) => {
    window.location.href = path;
  };

  const navigateToAddAgent = () => {
    navigate('/add-agent');
  };

  const navigateToForum = () => {
    navigate('/forum');
  };

  const handleLogout = () => {
    setUser(null);
    localStorage.removeItem('user-info');
    navigate('/login');
  };

  const renderAgents = () => {
    if (fetchError) {
      return <p style={{ textAlign: 'center' }}>You have no agents.</p>;
    } else if (filteredAgents.length > 0) {
      return filteredAgents.map(agent => (
        <Card key={agent._id} agent={agent} filter={agent.tag || agent.origin} filterColors={filterColors} />
      ));
    } else {
      return <p style={{ textAlign: 'center' }}>No agents found.</p>;
    }
  };

  return (
    <>
        {/* Loading Message */}
        {showLoadingMessage && (
      <div
        style={{
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: 'rgba(0, 0, 0, 0.8)',
          color: 'white',
          padding: '20px',
          borderRadius: '10px',
          zIndex: 1000,
          textAlign: 'center',
        }}
      >
        Loading agents, please wait...
      </div>
    )}
      <div style={{ maxWidth: '1200px', margin: '60px auto', padding: '20px' }}>
        <div className={`navButtonsContainer ${window.innerWidth <= 768 ? 'mobile' : ''}`}>
          {user ? (
            <>
              <button onClick={handleLogout} className={`navButton ${window.innerWidth <= 768 ? 'mobile' : ''}`}>Logout</button>
              <button onClick={toggleShowMyAgents} className={`navButton ${window.innerWidth <= 768 ? 'mobile' : ''}`}>
                {showMyAgents ? 'Show All Agents' : 'Show My Agents'}
              </button>
            </>
          ) : (
            <button onClick={() => urlNavigate('/login')} className={`navButton ${window.innerWidth <= 768 ? 'mobile' : ''}`}>Login</button>
          )}
          <button onClick={navigateToAddAgent} className={`navButton ${window.innerWidth <= 768 ? 'mobile' : ''}`}>Add New Agent</button>
          <button onClick={() => urlNavigate('/faqs')} className={`navButton ${window.innerWidth <= 768 ? 'mobile' : ''}`}>FAQs</button>
          <button onClick={() => window.location.href = 'https://gorilla.cs.berkeley.edu/blogs/11_agent_marketplace.html'} className={`navButton ${window.innerWidth <= 768 ? 'mobile' : ''}`}>Blog</button>
          <button onClick={navigateToForum} className={`navButton ${window.innerWidth <= 768 ? 'mobile' : ''}`}>Forum</button>
          <button onClick={() => {
                        toggleTheme();
                        window.location.reload();
                    }} className={`navButton ${window.innerWidth <= 768 ? 'mobile' : ''}`}>
                        {theme === 'light' ? 'Dark' : 'Light'} Mode
                    </button>        
          <button
            onClick={() => window.location.href = 'https://www.agent-arena.com/'}
            className={`navButton ${window.innerWidth <= 768 ? 'mobile' : ''}`}
            title="Run and rate LLM agents"
          >
            Agent Arena
          </button>
        </div>
        <h1 style={{ textAlign: 'center', fontSize: '2.5rem', margin: '20px 0 10px 0',color: theme === 'dark' ? 'white' : '#007bff', fontFamily: 'Arial' }}>Agents and Assistants Marketplace</h1>

        <p style={{
          textAlign: 'center',
          fontSize: '1.2rem',
          color: theme === 'dark' ? '#edebe6' : '#333',
          marginBottom: '10px',
          maxWidth: '800px',
          lineHeight: '1.8',
          margin: '0 auto 20px'
        }}>
          Opensource Search Engine for LLM Agents
        </p>
        <p style={taglineStyles}>
          Featuring over 150 certified agents from Langchain, Llamaindex, OpenAI, and CrewAI, all accessible through a unified interface with user reviews
        </p>

        <ProgressBar runningAgents={158} totalAgents={agents.length} />

        <input
          type="text"
          placeholder="Search for agents..."
          style={{
            width: 'calc(100% - 30px)',
            padding: '15px',
            marginBottom: '40px',
            fontSize: '18px',
            border: '2px solid #007bff',
            borderRadius: '25px',
            outline: 'none',
            boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
            transition: 'all 0.3s ease'
          }}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <div style={filterContainerStyles}>
          {filters.map(filter => (
            <button
              key={filter}
              onClick={() => toggleFilter(filter)}
              style={{
                ...filterButtonStyles(filter),
                backgroundColor: selectedFilters.includes(filter) ? '#007bff' : filterColors[filter].normal,
                color: selectedFilters.includes(filter) ? 'white' : '#007bff',
              }}
            >
              {filter}
            </button>
          ))}
          <button
            onClick={() => setSelectedFilters([])}
            style={clearFilterButtonStyles}
          >
            Clear Filters
          </button>
        </div>

        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(240px, 1fr))', gap: '50px', justifyContent: 'center' }}>
          {renderAgents()}
        </div>
      </div>
    </>
  );
};

export default HomePage;

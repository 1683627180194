import React, { useContext, useEffect, useState } from 'react';
import './ProgressBar.css';
import { ThemeContext } from '../ThemeContext';

const ProgressBar = ({ totalAgents }) => {
  const [verifiedAgents, setVerifiedAgents] = useState(0);
  const { theme } = useContext(ThemeContext);

  useEffect(() => {
    const fetchVerifiedAgentsCount = () => {
      fetch('https://agent-marketplace-2jmb.vercel.app/api/agents/verified_agents_count')
        .then(response => response.json())
        .then(data => {
          console.log("Verified agents count:", data.verified_count); // Debugging: Check API response
          setVerifiedAgents(data.verified_count);
        })
        .catch(error => console.error('Error fetching the verified agents count:', error));
    };

    fetchVerifiedAgentsCount(); // Fetch immediately on mount
    const interval = setInterval(fetchVerifiedAgentsCount, 5000); // Then every 5 seconds

    return () => clearInterval(interval);
  }, []);

  const progressPercentage = (verifiedAgents / totalAgents) * 100;

  return (
    <div className="progress-bar-container">
      <div className="progress-bar">
        <div className="progress" style={{ width: `${progressPercentage}%` }}></div>
      </div>
      <div className="progress-info" style={{ color: theme === 'dark' ? 'white' : 'black' }}>
        <span>{verifiedAgents} / {totalAgents} agents are passing all checks</span>
      </div>
    </div>
  );
};

export default ProgressBar;

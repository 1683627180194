import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaCheck } from 'react-icons/fa';

const Card = ({ agent, filter, filterColors }) => {
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);
  const [tooltipVisible, setTooltipVisible] = useState(false);

  const getCardColor = (filter, isHovered) => {
    const color = filterColors[filter] || { normal: '#f8bbd0', hover: '#e1a2b8' };
    return isHovered ? color.hover : color.normal;
  };

  const renderStars = (rating) => {
    let stars = [];
    for (let i = 1; i <= 5; i++) {
      stars.push(
        <span key={i} style={{ color: i <= rating ? '#FFD700' : '#e0e0e0' }}>★</span>
      );
    }
    return <div style={{ fontSize: '16px', marginRight: '10px' }}>{stars}</div>;
  };

  const cardStyle = {
    border: '1px solid #e0e0e0',
    borderRadius: '10px',
    padding: '20px',
    cursor: 'pointer',
    boxShadow: isHovered ? '0 10px 20px rgba(0,0,0,0.2)' : '0 5px 15px rgba(0,0,0,0.1)',
    transform: isHovered ? 'translateY(-10px)' : 'none',
    transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out, background-color 0.2s ease-in-out',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '250px',
    backgroundColor: getCardColor(filter, isHovered),
    position: 'relative'
  };

  const titleStyle = {
    marginBottom: '15px',
    fontSize: '1.4rem',
    fontWeight: '600',
    color: '#007bff',
  };

  const descriptionStyle = {
    fontSize: '1rem',
    color: '#666',
    overflow: 'auto',
    flexGrow: 1,
  };

  const ratingStyle = {
    display: 'flex',
    alignItems: 'center',
    marginTop: '10px',
    fontSize: '1rem',
    color: '#333',
  };

  const reviewCountStyle = {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '10px',
  };

  const reviewIconStyle = {
    width: '16px',
    height: '16px',
    marginRight: '5px',
  };

  const verifiedIconStyle = {
    position: 'absolute',
    top: '10px',
    right: '10px',
    fontSize: '18px',
    color: 'green',
    cursor: 'pointer',
  };

  const tooltipStyles = {
    position: 'absolute',
    top: '-30px',
    right: '0',
    backgroundColor: '#f9f9f9',
    padding: '5px 10px',
    borderRadius: '5px',
    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
    fontSize: '12px',
    zIndex: 1000,
    visibility: tooltipVisible ? 'visible' : 'hidden',
    opacity: tooltipVisible ? 1 : 0,
    transition: 'opacity 0.3s ease',
  };

  return (
    <div 
      style={cardStyle} 
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={() => navigate(`/agents/${agent._id}`)}
    >
      {agent.verified && (
        <>
          <FaCheck 
            style={verifiedIconStyle} 
            onMouseEnter={() => setTooltipVisible(true)}
            onMouseLeave={() => setTooltipVisible(false)}
          />
          <div style={tooltipStyles}>
            Verified
          </div>
        </>
      )}
      <h3 style={titleStyle}>{agent.name}</h3>
      <p style={descriptionStyle}>{agent.description}</p>
      <div style={ratingStyle}>
        {renderStars(agent.averageRating)}
        <span>{agent.averageRating.toFixed(1)}</span>
        <div style={reviewCountStyle}>
          <span>(</span>
          <img src="user.png" alt="Reviews" style={reviewIconStyle} />
          <span>{agent.numberOfReviews})</span>
        </div>
      </div>
    </div>
  );
};

export default Card;

import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import UserContext from '../UserContext';
import { ThemeContext } from '../ThemeContext';
import { FaThumbsUp, FaThumbsDown, FaCommentAlt } from 'react-icons/fa';

import '../App.css';
import './Nav.css'; // Import the CSS file

axios.defaults.baseURL = 'https://agent-marketplace-2jmb.vercel.app/api';

const Forum = () => {
    const [posts, setPosts] = useState([]);
    const [newPostTitle, setNewPostTitle] = useState('');
    const [newPostContent, setNewPostContent] = useState('');
    const { user, setUser } = useContext(UserContext);
    const navigate = useNavigate();
    const [sortOption, setSortOption] = useState('newest');
    const { theme, toggleTheme } = useContext(ThemeContext);

    useEffect(() => {
        fetchPosts();
    }, [sortOption]);

    const fetchPosts = async () => {
        try {
            const response = await axios.get('/forum/posts');
            let fetchedPosts = Array.isArray(response.data) ? response.data : [];
            
            if (sortOption === 'mostUpvotes') {
                fetchedPosts.sort((a, b) => b.upvotes - a.upvotes);
            } else if (sortOption === 'mostComments') {
                fetchedPosts.sort((a, b) => b.comments.length - a.comments.length);
            } else {
                fetchedPosts.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
            }

            setPosts(fetchedPosts);
        } catch (error) {
            console.error('Error fetching posts:', error);
            setPosts([]);
        }
    };

    const handleNewPost = async () => {
        try {
            const response = await axios.post('/forum/posts', { title: newPostTitle, content: newPostContent });
            setPosts([...posts, response.data]);
            setNewPostTitle('');
            setNewPostContent('');
        } catch (error) {
            console.error('Error creating a new post:', error);
        }
    };

    const handleUpvotePost = async (postId) => {
        try {
            await axios.post(`/forum/posts/${postId}/upvote`);
            fetchPosts();
        } catch (error) {
            console.error('Error upvoting post:', error);
        }
    };

    const handleDownvotePost = async (postId) => {
        try {
            await axios.post(`/forum/posts/${postId}/downvote`);
            fetchPosts();
        } catch (error) {
            console.error('Error downvoting post:', error);
        }
    };

    const handleLogout = () => {
        setUser(null);
        localStorage.removeItem('user-info');
        navigate('/login');
    };

    const navigateToAddAgent = () => {
        navigate('/add-agent');
    };

    const navigateToPost = (postId) => {
        navigate(`/forum/posts/${postId}`);
    };

    const handleSortChange = (e) => {
        setSortOption(e.target.value);
    };

    return (
        <>
            <div style={{ maxWidth: '1200px', margin: '60px auto' }}>
            <div className={`navButtonsContainer ${window.innerWidth <= 768 ? 'mobile' : ''}`}>
            <button onClick={() => navigate('/')} className={`navButton ${window.innerWidth <= 768 ? 'mobile' : ''}`}>Home</button> 

          {user ? (
            <>
              <button onClick={handleLogout} className={`navButton ${window.innerWidth <= 768 ? 'mobile' : ''}`}>Logout</button>

            </>
          ) : (
            <button onClick={() => navigate('/login')} className={`navButton ${window.innerWidth <= 768 ? 'mobile' : ''}`}>Login</button>
          )}
          <button onClick={navigateToAddAgent} className={`navButton ${window.innerWidth <= 768 ? 'mobile' : ''}`}>Add New Agent</button>
          <button onClick={() => navigate('/faqs')} className={`navButton ${window.innerWidth <= 768 ? 'mobile' : ''}`}>FAQs</button>
          <button onClick={() => window.location.href = 'https://gorilla.cs.berkeley.edu/blogs/11_agent_marketplace.html'} className={`navButton ${window.innerWidth <= 768 ? 'mobile' : ''}`}>Blog</button>
          <button onClick={() => {
                        toggleTheme();
                        window.location.reload();
                    }} className={`navButton ${window.innerWidth <= 768 ? 'mobile' : ''}`}>
                        {theme === 'light' ? 'Dark' : 'Light'} Mode
                    </button>  
        <button
            onClick={() => window.location.href = 'https://www.agent-arena.com/'}
            className={`navButton ${window.innerWidth <= 768 ? 'mobile' : ''}`}
            title="Run and rate LLM agents"
          >
            Agent Arena
          </button>     
        </div>
            </div>

            <div style={{ 
                maxWidth: '800px', 
                margin: '20px auto', 
                padding: '20px', 
                backgroundColor: theme === 'dark' ? '#28293d' : '#f9f9f9', 
                borderRadius: '10px', 
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' 
            }}>
                <h1 className="title" style={{ color: theme === 'dark' ? 'white' : '#333' }}>Forum</h1>
                <p className="subtitle" style={{ color: theme === 'dark' ? 'white' : '#666' }}>Share your ideas and feedback, and engage with the community.</p>

                <div>
                    <h2 style={{ color: theme === 'dark' ? 'white' : '#333' }}>New Post</h2>
                    <input
                        type="text"
                        className="inputField"
                        value={newPostTitle}
                        onChange={(e) => setNewPostTitle(e.target.value)}
                        placeholder="Title"
                        style={{ 
                            backgroundColor: theme === 'dark' ? '#1a1a2e' : 'white', 
                            color: theme === 'dark' ? 'white' : '#333', 
                            border: `1px solid ${theme === 'dark' ? '#6a0dad' : '#ddd'}` 
                        }}
                    />
                    <textarea
                        className="inputField"
                        value={newPostContent}
                        onChange={(e) => setNewPostContent(e.target.value)}
                        placeholder="Content"
                        style={{ 
                            backgroundColor: theme === 'dark' ? '#1a1a2e' : 'white', 
                            color: theme === 'dark' ? 'white' : '#333', 
                            border: `1px solid ${theme === 'dark' ? '#6a0dad' : '#ddd'}` 
                        }}
                    />
                    <button className="button" style={{ marginBottom: '20px', backgroundColor: '#007bff', color: 'white' }} onClick={handleNewPost}>Submit</button>
                </div>

                <div>
                    <select 
                        value={sortOption} 
                        onChange={handleSortChange} 
                        style={{ 
                            marginBottom: '20px', 
                            padding: '10px', 
                            border: `1px solid ${theme === 'dark' ? '#6a0dad' : '#ddd'}`, 
                            borderRadius: '5px', 
                            backgroundColor: theme === 'dark' ? '#1a1a2e' : 'white', 
                            color: theme === 'dark' ? 'white' : '#333' 
                        }}
                    >
                        <option value="newest">Newest</option>
                        <option value="mostUpvotes">Most Upvotes</option>
                        <option value="mostComments">Most Comments</option>
                    </select>
                </div>

                <div>
                    {posts.length > 0 ? (
                        posts.map(post => (
                            <div 
                                key={post._id} 
                                className="post hoverable" 
                                onClick={() => navigateToPost(post._id)} 
                                style={{ 
                                    cursor: 'pointer', 
                                    marginBottom: '20px', 
                                    backgroundColor: theme === 'dark' ? '#1a1a2e' : 'white', 
                                    color: theme === 'dark' ? 'white' : '#333', 
                                    border: `1px solid ${theme === 'dark' ? '#6a0dad' : '#ddd'}`, 
                                    borderRadius: '5px' 
                                }}
                            >
                                <h3>{post.title}</h3>
                                <p>{post.content}</p>
                                <div className="voteButtonsContainer">
                                    <button 
                                        className="voteButton" 
                                        onClick={(e) => { e.stopPropagation(); handleUpvotePost(post._id); }}
                                    >
                                        <FaThumbsUp style={{ color: '#007bff' }} /> 
                                        <span style={{ color: '#007bff' }}>Upvote ({post.upvotes})</span>
                                    </button>
                                    <button 
                                        className="voteButton" 
                                        onClick={(e) => { e.stopPropagation(); handleDownvotePost(post._id); }}
                                    >
                                        <FaThumbsDown style={{ color: 'red' }} /> 
                                        <span style={{ color: 'red' }}>Downvote ({post.downvotes})</span>
                                    </button>
                                    <button 
                                        className="voteButton" 
                                        onClick={(e) => { e.stopPropagation(); navigateToPost(post._id); }}
                                    >
                                        <FaCommentAlt /> 
                                        <span style={{ color: theme === 'dark' ? 'white' : '#333' }}>Comments ({post.comments.length})</span> 
                                    </button>
                                </div>
                            </div>
                        ))
                    ) : (
                        <p style={{ color: theme === 'dark' ? 'white' : '#333' }}>No posts available</p>
                    )}
                </div>
            </div>
        </>
    );
};

export default Forum;
